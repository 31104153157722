import { Message } from 'element-ui'

export default function ({ $axios, error: nuxtError, $sentry }) {
  $axios.defaults.timeout = 300000

  $axios.onRequest((config) => {
    // Mock set
    if (process.env.mockBaseURL) {
      if (
        !process.env.mockList ||
        process.env.mockList.some((path) => config.url.includes(path))
      ) {
        config.baseURL = process.env.mockBaseURL
      }
    }

    if (process.env.releaseVersion) {
      config.headers['X-Release-Version'] = process.env.releaseVersion
    }

    return config
  })

  $axios.onError((error) => {
    if ($sentry) {
      const filterNoNeedErros = [
        'Both token and refresh token have expired. Your request was aborted.',
      ]

      if (!filterNoNeedErros.includes(error?.message)) {
        $sentry.captureException(error)
      }
    }

    const { response } = error

    if (response && /4[0-9]{2}/.test(response.status)) {
      const errMsg = response.data?.message || 'エラーが発生しました。'

      Message({
        message: errMsg,
        type: 'info',
        customClass: 'toast',
      })

      return response
    }

    nuxtError(error)
  })
}
