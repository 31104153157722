export default async function ({ $auth, $axios, store, route, $sentry }) {
  $sentry.setContext('device', {
    userAgent: navigator.userAgent,
    devicePixelRatio: window.devicePixelRatio,
    screenWidth: window.screen?.availWidth,
    screenHeight: window.screen?.availHeight,
    screenOrientationType: window.screen?.orientation?.type,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    language: navigator.language,
  })

  const isCodeLogin = !!(route.path === '/login' && route.query.code)

  if (!isCodeLogin && $auth.loggedIn && store.state.downloadList.length < 1) {
    const res = await $axios.$get('/download/list')

    const { list } = res.data || {}

    if (list) {
      store.commit('updateDownloadList', list)
    }
  }
}
